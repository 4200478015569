import {factoryActionType} from 'utils';

const START_ASSIGNMENT = 'START_ASSIGNMENT';
const COMPLETE_ASSIGNMENT = 'COMPLETE_ASSIGNMENT';
const {
  START_ASSIGNMENT_START,
  START_ASSIGNMENT_SUCCESS,
  START_ASSIGNMENT_FAIL,
  COMPLETE_ASSIGNMENT_START,
  COMPLETE_ASSIGNMENT_SUCCESS,
  COMPLETE_ASSIGNMENT_FAIL,
} = factoryActionType([START_ASSIGNMENT, COMPLETE_ASSIGNMENT]);

export {
  START_ASSIGNMENT,
  START_ASSIGNMENT_START,
  START_ASSIGNMENT_SUCCESS,
  START_ASSIGNMENT_FAIL,
  COMPLETE_ASSIGNMENT,
  COMPLETE_ASSIGNMENT_START,
  COMPLETE_ASSIGNMENT_SUCCESS,
  COMPLETE_ASSIGNMENT_FAIL,
};
