// @flow
import React, {Node} from 'react';

const DefaultProps = {
  fill: '#666666',
  className: '',
};

type PropType = {
  fill?: string,
  className?: string
};

const NoticeIcon = (props: PropType): Node => {
  const { fill, className, ...svgProps } = props;
  return (
    <svg
      width="32"
      height="33"
      viewBox="8 8 16 16"
      fill={fill}
      className={`${className} svg`}
      {...svgProps}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1346 23.1429C16.1346 23.2232 16.0757 23.2857 16 23.2857C15.1839 23.2857 14.5192 22.5804 14.5192 21.7143C14.5192 21.6339 14.5781 21.5714 14.6538 21.5714C14.7296 21.5714 14.7885 21.6339 14.7885 21.7143C14.7885 22.4196 15.3353 23 16 23C16.0757 23 16.1346 23.0625 16.1346 23.1429ZM10.5312 20.5714C12.0373 18.7679 12.7692 16.3214 12.7692 13.1429C12.7692 11.9911 13.7957 10.2857 16 10.2857C18.2043 10.2857 19.2308 11.9911 19.2308 13.1429C19.2308 16.3214 19.9627 18.7679 21.4688 20.5714H10.5312ZM23 20.5714C21.7548 19.4554 20.3077 17.4554 20.3077 13.1429C20.3077 11.4286 18.97 9.55357 16.7404 9.20536C16.7825 9.09821 16.8077 8.98214 16.8077 8.85714C16.8077 8.38393 16.4459 8 16 8C15.5541 8 15.1923 8.38393 15.1923 8.85714C15.1923 8.98214 15.2175 9.09821 15.2596 9.20536C13.03 9.55357 11.6923 11.4286 11.6923 13.1429C11.6923 17.4554 10.2452 19.4554 9 20.5714C9 21.1964 9.48798 21.7143 10.0769 21.7143H13.8462C13.8462 22.9732 14.8137 24 16 24C17.1863 24 18.1538 22.9732 18.1538 21.7143H21.9231C22.512 21.7143 23 21.1964 23 20.5714Z" />
    </svg>
  );
};

NoticeIcon.defaultProps = DefaultProps;

export default NoticeIcon;
