import {factoryActionType} from 'utils';

const GET_CERTIFICATIONS = 'GET_CERTIFICATIONS';
const GET_CERTIFICATIONS_LOGS = 'GET_CERTIFICATIONS_LOGS';
const MAKE_BOOKING = 'MAKE_BOOKING';
const CANCEL_BOOKING = 'CANCEL_BOOKING';

const {
  GET_CERTIFICATIONS_START,
  GET_CERTIFICATIONS_SUCCESS,
  GET_CERTIFICATIONS_FAIL,
  GET_CERTIFICATIONS_LOGS_START,
  GET_CERTIFICATIONS_LOGS_SUCCESS,
  GET_CERTIFICATIONS_LOGS_FAIL,
  MAKE_BOOKING_START,
  MAKE_BOOKING_SUCCESS,
  MAKE_BOOKING_FAIL,
  CANCEL_BOOKING_START,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAIL,
} = factoryActionType([
  GET_CERTIFICATIONS,
  GET_CERTIFICATIONS_LOGS,
  MAKE_BOOKING,
  CANCEL_BOOKING,
]);

export {
  GET_CERTIFICATIONS,
  GET_CERTIFICATIONS_START,
  GET_CERTIFICATIONS_SUCCESS,
  GET_CERTIFICATIONS_FAIL,
  GET_CERTIFICATIONS_LOGS,
  GET_CERTIFICATIONS_LOGS_START,
  GET_CERTIFICATIONS_LOGS_SUCCESS,
  GET_CERTIFICATIONS_LOGS_FAIL,
  MAKE_BOOKING,
  MAKE_BOOKING_START,
  MAKE_BOOKING_SUCCESS,
  MAKE_BOOKING_FAIL,
  CANCEL_BOOKING,
  CANCEL_BOOKING_START,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAIL,
};
