import { factoryActionType } from 'utils';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  REMOVE_NOTIFICATION_START,
  REMOVE_NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION_FAIL,
} = factoryActionType([GET_NOTIFICATIONS, REMOVE_NOTIFICATION]);

export {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_START,
  REMOVE_NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION_FAIL,
  ADD_NOTIFICATION,
};
