import factoryActionTypes from 'utils/factoryActionType';

const LOGIN_IN_REQUEST = 'LOGIN_IN_REQUEST';
const LOGIN_OFF = 'LOGIN_OFF';
const LOGIN_DEFAULT = 'LOGIN_DEFAULT';
const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR';
const UPLOAD_USER_AVATAR_START = 'UPLOAD_USER_AVATAR_START';
const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS';
const UPLOAD_USER_AVATAR_FAIL = 'UPLOAD_USER_AVATAR_FAIL';
const GET_CURRENT_PROFILE = 'GET_CURRENT_PROFILE';
const PUT_CURRENT_PROFILE = 'PUT_CURRENT_PROFILE';
const PUT_CURRENT_PROFILE_START = 'PUT_CURRENT_PROFILE_START';
const PUT_CURRENT_PROFILE_SUCCESS = 'PUT_CURRENT_PROFILE_SUCCESS';
const PUT_CURRENT_PROFILE_FAIL = 'PUT_CURRENT_PROFILE_FAIL';
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';
const CLOSE_ACCOUNT_START = 'CLOSE_ACCOUNT_START';
const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
const CLOSE_ACCOUNT_FAIL = 'CLOSE_ACCOUNT_FAIL';
const EXPORT_DATA = 'EXPORT_DATA';
const EXPORT_DATA_START = 'CLOSE_ACCOUNT_START';
const EXPORT_DATA_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
const EXPORT_DATA_FAIL = 'CLOSE_ACCOUNT_FAIL';
const ADD_NEW_EMAIL = 'ADD_NEW_EMAIL';
const ADD_NEW_EMAIL_START = 'ADD_NEW_EMAIL_START';
const ADD_NEW_EMAIL_SUCCESS = 'ADD_NEW_EMAIL_SUCCESS';
const ADD_NEW_EMAIL_FAIL = 'ADD_NEW_EMAIL_FAIL';
const types = factoryActionTypes(
  ['LOGIN_IN', 'GET_CURRENT_PROFILE'],
);

export default {
  GET_CURRENT_USER_REQUEST,
  LOGIN_DEFAULT,
  LOGIN_OFF,
  LOGIN_IN_REQUEST,
  UPLOAD_USER_AVATAR,
  UPLOAD_USER_AVATAR_START,
  UPLOAD_USER_AVATAR_SUCCESS,
  UPLOAD_USER_AVATAR_FAIL,
  GET_CURRENT_PROFILE,
  PUT_CURRENT_PROFILE,
  PUT_CURRENT_PROFILE_START,
  PUT_CURRENT_PROFILE_SUCCESS,
  PUT_CURRENT_PROFILE_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLOSE_ACCOUNT,
  CLOSE_ACCOUNT_START,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_FAIL,
  EXPORT_DATA,
  EXPORT_DATA_START,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAIL,
  ADD_NEW_EMAIL,
  ADD_NEW_EMAIL_START,
  ADD_NEW_EMAIL_SUCCESS,
  ADD_NEW_EMAIL_FAIL,
  ...types,
};
