import {factoryActionType} from 'utils';

const GET_MY_COURSE = 'GET_MY_COURSE';
const GET_MY_COURSES = 'GET_MY_COURSES';
const GET_WELCOME_LETTER_URL = 'GET_WELCOME_LETTER_URL';
const GET_WELCOME_LETTER_RICH_TEXT = 'GET_WELCOME_LETTER_RICH_TEXT';
const {
  GET_MY_COURSE_START,
  GET_MY_COURSE_SUCCESS,
  GET_MY_COURSE_FAIL,
  GET_MY_COURSES_START,
  GET_MY_COURSES_SUCCESS,
  GET_MY_COURSES_FAIL,
} = factoryActionType([GET_MY_COURSE, GET_MY_COURSES]);

export {
  GET_MY_COURSE,
  GET_MY_COURSE_START,
  GET_MY_COURSE_SUCCESS,
  GET_MY_COURSE_FAIL,
  GET_MY_COURSES,
  GET_MY_COURSES_START,
  GET_MY_COURSES_SUCCESS,
  GET_MY_COURSES_FAIL,
  GET_WELCOME_LETTER_URL,
  GET_WELCOME_LETTER_RICH_TEXT,
};
