import factoryActionTypes from 'utils/factoryActionType';

const GET_ALL_MY_ORGANISATION_REQUEST = 'GET_ALL_MY_ORGANISATION_REQUEST';
const SET_MY_ORGANISATION_ID = 'SET_MY_ORGANISATION_ID';
const ADD_MY_ORGANISATION = 'ADD_MY_ORGANISATION';
const GET_MY_ORGANISATION_INITIALIZATION = 'GET_MY_ORGANISATION_INITIALIZATION';
const GET_MY_ORGANISATION_INFORMATION_REQUEST = 'GET_MY_ORGANISATION_INFORMATION_REQUEST';

const types = factoryActionTypes(
  ['GET_ALL_MY_ORGANISATION', 'GET_MY_ORGANISATION_INFORMATION'],
);

export default {
  GET_MY_ORGANISATION_INITIALIZATION,
  GET_ALL_MY_ORGANISATION_REQUEST,
  ADD_MY_ORGANISATION,
  SET_MY_ORGANISATION_ID,
  GET_MY_ORGANISATION_INFORMATION_REQUEST,
  ...types,
};
