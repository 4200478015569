import { factoryActionType } from 'utils';

const FETCH_MY_CERTIFICATES = 'FETCH_MY_CERTIFICATES';
const {
  FETCH_MY_CERTIFICATES_START,
  FETCH_MY_CERTIFICATES_SUCCESS,
  FETCH_MY_CERTIFICATES_FAIL,
} = factoryActionType([FETCH_MY_CERTIFICATES]);

export {
  FETCH_MY_CERTIFICATES,
  FETCH_MY_CERTIFICATES_START,
  FETCH_MY_CERTIFICATES_SUCCESS,
  FETCH_MY_CERTIFICATES_FAIL,
};
