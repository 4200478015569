const APP_CAN_RENDER = 'APP_CAN_RENDER';
const SEND_SUPPORT_MESSAGE_REQUEST = 'SEND_SUPPORT_MESSAGE_REQUEST';
const SEND_SUPPORT_MESSAGE_START = 'SEND_SUPPORT_MESSAGE_START';
const SEND_SUPPORT_MESSAGE_SUCCESS = 'SEND_SUPPORT_MESSAGE_SUCCESS';
const SEND_SUPPORT_MESSAGE_FAIL = 'SEND_SUPPORT_MESSAGE_FAIL';

export {
  APP_CAN_RENDER,
  SEND_SUPPORT_MESSAGE_REQUEST,
  SEND_SUPPORT_MESSAGE_START,
  SEND_SUPPORT_MESSAGE_SUCCESS,
  SEND_SUPPORT_MESSAGE_FAIL,
};
